<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
  >
    <!-- @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)" -->
    <!-- main menu header-->
    <div class="navbar-header expanded pl-1">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <div class="mt-2 pl-1" v-if="collapseTogglerIcon == 'unpinned'">
            <b-link>
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="ml-0 d-none d-xl-block collapse-toggle-icon text-primary"
                @click="toggleCollapsed"
              />
            </b-link>
        </div>
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li :class="collapseTogglerIcon == 'pinned' ? 'nav-item mx-auto d-flex justify-content-center' : 'nav-item mr-auto'">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span>
                <b-img
                  src="../../../../../assets/images/logo/logo2.png"
                  alt="logo"
                  width="50%"
                />
              </span>
              <h2 class="brand-text" style="color: #E84185; font-size: 1.20rem">
                <!-- {{ appName }} -->
              </h2>
            </b-link>
          </li>


          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle" style="margin-top: 28px">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="ml-0 d-none d-xl-block collapse-toggle-icon text-primary"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>

        <div :class="collapseTogglerIcon == 'pinned' ? 'd-flex justify-content-center pt-2' : 'mr-auto pt-2'">
          <b-link class="text-center mr-5">
            <b-avatar
              :size="collapseTogglerIcon == 'pinned' ? 70 : 50"
              :src="userData.avatar"
              variant="light-primary"
              badge
              class="badge-minimal custom-avatar"
              badge-variant="success"
            >
              <feather-icon
                v-if="!userData.avatar"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
            <p :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-white mt-50' : 'text-dark mt-50 font-weight-bolder'" v-if="collapseTogglerIcon == 'pinned'">{{ `${authUser().user.fname} ${authUser().user.lname}` }}</p>
          </b-link>
        </div>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        :collapseTogglerProp="collapseTogglerIcon"
        class="navigation navigation-main mt-2"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg, BAvatar, } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import {getAuth} from '@/auth/utils.js'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BAvatar,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userData: {
        avatar: '',
      },
      // avatarText,
    }
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'ArrowRightIcon' : 'ArrowLeftIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  mounted(){  
    this.userData = getAuth().user
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
.text-primary{
  color: #E84185 !important;
}

.custom-avatar{
  background: linear-gradient(0deg, rgba(105,34,149,1) 0%, rgba(232,65,133,1) 100%) !important;
}

.main-menu.menu-light .navigation > li > ul li:not(.has-sub) {
    margin: 0 -8px !important;
}
</style>
