<template>
  <!-- v-if="rerender" -->
  <ul class="nav-menu" >
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in nonLogoutItems"
      :key="item.header || item.title"
      :item="item"
      v-if="hasPermission(item.Permission) || item.Permission == 'Public'"
    />
      <!-- v-b-tooltip.hover.right="collapseTogglerProp == 'unpinned' ? (item.header || item.title) : false" -->
      <!-- v-if="hasPermission(item.permission)" -->

      <!-- Render the 'Logout' item at the bottom -->
    
    <component
      class="logout-item"
      :is="resolveNavItemComponent(logoutItem)"
      v-if="logoutItem && (hasPermission(logoutItem.Permission) || logoutItem.Permission === 'Public')"
      :key="logoutItem.header || logoutItem.title"
      :item="logoutItem"
    />
      <!-- v-b-tooltip.hover.right="collapseTogglerProp == 'unpinned' ? (logoutItem.header || logoutItem.title) : false" -->
  </ul>
  <!-- <div class="d-flex justify-content-center align-items-center" v-else>
    <b-spinner variant="primary" label="Spinning"></b-spinner>
  </div> -->
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { mapGetters } from "vuex"
import { provide, ref, computed } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BAvatar,
  BLink,
  VBTooltip,
  BSpinner,
  BPagination,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BSpinner
  },
  props: {
    collapseTogglerProp: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapGetters({
      rerender: "app/rerender",
    }),
  },
  setup(props) {
    provide('openGroups', ref([]))

    const logoutItem = computed(() => props.items.find(item => item.title === 'Logout'));
    const nonLogoutItems = computed(() => props.items.filter(item => item.title !== 'Logout'));


    return {
      resolveNavItemComponent,
      logoutItem,
      nonLogoutItems,
    }
  },
}
</script>

<style scoped>
.nav-menu {
  display: flex !important;
  flex-direction: column !important;
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important;
  height: 65vh !important;
}

.logout-item {
  margin-top: auto !important;
}
</style>
